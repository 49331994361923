@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap");

* {
    font-family: "Poppins", sans-serif !important;
    padding: auto;
}

@media (max-width: 600px) {
    * {
        font-family: "Plus Jakarta Sans", sans-serif !important;
        padding: auto;
    }
}

.job-list::-webkit-scrollbar {
    display: none;
}

.job-list {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@media (max-width: 430px) {
    .nav-mobile-link {
        padding-left: 10px !important;
        padding-right: 10px !important;
        padding-top: 14px !important;
    }

    .button-group-text {
        font-size: 12px !important;
    }

    .login__mob-heading {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.table-scroll::-webkit-scrollbar {
    height: 5px;
}

/* Track */
.table-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px silver;
    border-radius: 10px;
}

/* Handle */
.table-scroll::-webkit-scrollbar-thumb {
    background: rgb(150, 150, 150);
    border-radius: 10px;
}

/* Handle on hover */
.table-scroll::-webkit-scrollbar-thumb:hover {
    background: rgb(112, 112, 112);
}